import React, { PureComponent } from "react";

import classes from "./Testimonials.module.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Fade from "react-reveal/Fade";

class Testimonials extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className={classes.testimonials}>
        <Container fluid>
          <Fade top>
            <div className={classes.header}>Our Clients Say it Best...</div>
          </Fade>
          <Fade top>
            <div style={{ maxWidth: "25rem", margin: "0 auto" }}>
              <hr />
            </div>
          </Fade>
          <br />
          <Row>
            <Col md>
              <Fade bottom>
                <div className={classes.card}>
                  <div className={classes.container}>
                    {/* <div className={classes.quotes}>"</div> */}
                    <div className={classes.body}>
                      "In today’s fast-paced and ever-changing environment,
                      identifying the right technology partner who understands
                      how to optimize security, performance and efficiency is
                      critical. For STAR Bank, that partner is LincLogix. From
                      serving as one of the architects of our network
                      infrastructure, to assisting with the crafting of RFPs, to
                      project management, identifying cost-cutting opportunities
                      and more, they consistently offer sound advice and are an
                      indispensable part of our team. LincLogix works to
                      holistically understand our core values and strategic
                      direction; their work ethic, reliability, responsiveness
                      and emphasis on communication make them one of our
                      strongest partners."
                    </div>
                    {/* <br /> */}
                    {/* <FontAwesomeIcon icon="quote-right" style={{ float: "right" }} /> */}
                  </div>
                  <div className={classes.author}>
                    <strong>Kristin Marcuccilli</strong>
                    <br />
                    SVP, Chief Operating Officer STAR Financial Bank
                  </div>
                </div>
              </Fade>
              <br />
            </Col>

            <Col md>
              <Fade bottom>
                <div className={classes.card}>
                  <div className={classes.container}>
                    <div className={classes.body}>
                      "Wabash National and LincLogix have worked together for a
                      little less than six years. The entire staff at LincLogix
                      is very professional and provide exemplary customer
                      service and support. This is a great asset to have,
                      especially working in the telecommunications field.
                      LincLogix is there to drive issues, assist in overall
                      design, and seek solutions that fit the immediate need as
                      well as considering the future vision that will best suit
                      Wabash National’s needs. With LincLogix’s expertise,
                      Wabash National has reduced telecommunications expenses
                      while at the same time increasing telecommunication
                      quality and services. In short, LincLogix makes my job
                      easier and has been a great asset for Wabash National."
                    </div>
                  </div>
                  <div className={classes.author}>
                    <strong>Joe Morgan</strong>
                    <br />
                    Telecommunications Coordinator Wabash National
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Testimonials;
