import React, { PureComponent } from "react";

import classes from "./Knowledge.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Slide from "react-reveal/Slide";
import { Parallax } from "react-parallax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Knowledge extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Parallax
          bgImage={require("../../assets/phone_man.jpg")}
          bgImageAlt="picture of the back of a computer"
          strength={500}
          className={classes.outerMargin}
        >
          <Row>
            <Col>
              <Slide right>
                <div className={classes.card}>
                  <div className={classes.container}>
                    <div className={classes.header}>
                      Knowledge
                      <span style={{ float: "right" }}>
                        <FontAwesomeIcon icon={["fas", "lightbulb"]} />
                      </span>
                    </div>
                    <hr />
                    <div className={classes.body}>
                      From telecommunications authorities to engineers and
                      business experts, our team stands ready to complement your
                      team, in exactly the capacity you need.
                      <br />
                      <br />
                      Once you're up and running, that’s just the beginning. We
                      continue working on your behalf and providing continuing
                      education to keep you abreast of emerging industry trends.
                      <br />
                      <br />
                      Expert knowledge. Cutting-edge technology. Cost effective.
                      Better customer service. Small but mighty. Let us help you
                      manage the complex life cycle of Information Technology.
                    </div>
                  </div>
                </div>
              </Slide>
            </Col>
          </Row>
        </Parallax>
      </div>
    );
  }
}

export default Knowledge;
