import React, { PureComponent } from "react";
import classes from "./Team.module.css";
import Fade from "react-reveal/Fade";

// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";

import Slider from "react-slick";

import TeamCard from "./TeamCard";

class Team extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1650,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className={classes.team}>
        <Fade top>
          <div className={classes.header}>Meet The Team</div>
        </Fade>
        <Fade top>
          <div
            style={{
              maxWidth: "25rem",
              margin: "0 auto",
              marginBottom: "-3rem",
              padding: "1rem",
            }}
          >
            <hr />
          </div>
        </Fade>
        <Fade bottom>
          <Slider {...settings}>
            <div className={classes.sliderDiv}>
              <TeamCard
                name={"Craig Eigenbrod"}
                position={"President - Founder"}
                image={"craig.jpg"}
                about={
                  'Craig is the President of LincLogix. His goal for every client is for them to ask "How on earth did we ever survive without these guys?  IT has never been so much fun!"'
                }
                accomplishments={
                  "Craig founded LincLogix in 2001 and over the last 19 years he has devoted himself to creating the best customer experience possible. After starting with just himself, Craig has expanded LincLogix and grown the company into what it is today."
                }
                funFacts={
                  "Craig is married to Kay (25 yrs) and has 3 great kids: Julie (21), Max (19), Erik (15). He also hosts 2 rescue kitties, Chickpea and Harry. His favorite season is Spring, because he is again reminded of Mother Nature's awesome beauty."
                }
              />
            </div>
            <div className={classes.sliderDiv}>
              <TeamCard
                name={"Michael Hawkins, CISSP"}
                position={"Director of Client Engineering"}
                image={"michael.JPG"}
                about={
                  "Michael Hawkins is the Director of Client Engineering for LincLogix.  Michael started his career in the financial and manufacturing sectors focused on networking technologies.  He branched out to the pharmaceutical sector with a focus on security technologies. Michael also worked for a large telecommunications company for several years where he was tasked with evangelizing networking and security technologies and best practices as a principal engineer."
                }
                accomplishments={
                  "During his time in the Telecom Industry he helped write Hacking Exposed, Linux Third Edition with his security group ISECOM. He also holds several highly recognized industry network and security certifications."
                }
                funFacts={
                  "Michael enjoys spending his free time with family and friends.  He enjoys MMA, Fast Cars, and anything that has a user interface he can mess with.  "
                }
              />
            </div>
            <div className={classes.sliderDiv}>
              <TeamCard
                name={"Lauren Lloyd"}
                position={"Senior Project Manager"}
                image={"lauren.jpg"}
                about={
                  "Lauren Lloyd is a Senior Project Manager at LincLogix.  She strives to deliver value and a superior client experience, working with cross-functional teams to manage and implement strategic voice and data solutions for clients.  Lauren manages large enterprise implementations that include complex SIP, SDWAN, cable & fiber as well as IoT."
                }
                accomplishments={
                  "Lauren has over 16 years of experience in the Information Technology industry. "
                }
                funFacts={
                  "Lauren is married and has a 9-year-old daughter.  She enjoys traveling and experimenting in the kitchen."
                }
              />
            </div>
            <div className={classes.sliderDiv}>
              <TeamCard
                name={"Kate Fletcher"}
                position={"Senior Project Manager"}
                image={"kate.jpg"}
                about={
                  "Kate has been a member of the LincLogix teams since 2018. She uses her skills from Indiana University to give her clients a personalized experience that meets their every needs."
                }
                funFacts={
                  "Kate’s favorite genre of music is Country and there are too many good artists for her to pick a favorite. In her free time, Kate loves to travel and has visited Costa Rica, The Dominican Republic, Mexico, England, Italy, and Spain"
                }
                accomplishments={
                  "Kate has been a Project Manager in the telecommunications industry for over ten years. She enjoys the work she does and has found how to put her OCD to good use, by employing her attention to detail to her projects, ensuring her clients always get the best possible service"
                }
              />
            </div>
            <div className={classes.sliderDiv}>
              <TeamCard
                name={"Ryan Stewart"}
                position={"Sales and Operational Support and Development"}
                image={"ryan.jpg"}
                about={
                  "Ryan heads up all of the pricing and contracting initiatives for LincLogix as well as sales and operational support and development. She has been a critical team member with the firm since October of 2015. Ryan graduated from Indiana University and focuses her time on ensuring that LincLogix’s clients are supported from introduction through Day 2 support and works closely with the entire LincLogix team and client base."
                }
                accomplishments={
                  "Ryan maintained her status as a Dean’s List Recipient and earned her Bachelor of Science in Kinesiology from Indiana University. After graduation she worked at two highly respected national organizations before joining the team at LincLogix in 2015."
                }
                funFacts={
                  "Ryan has a daughter, Sophia (10), 2 German Shepherds, Maxx and Freya, and lives a very active lifestyle outside of work. She enjoys running, good food, travel and spending time with her family."
                }
              />
            </div>
            <div className={classes.sliderDiv}>
              <TeamCard
                name={"Hugh Bromund"}
                position={"Technology Consultant"}
                image={"hugh.jpg"}
                about={
                  "Hugh works as the Summer Tech Intern at LincLogix. He is a Junior attending Purdue University and is majoring in Computer Science with a minor in Entrepreneurship and Innovation"
                }
                accomplishments={
                  "Hugh is an Eagle Scout. He is also a member of the Dean's List and has achieved semester honors while at Purdue."
                }
                funFacts={
                  "Hugh has hiked over 100 miles on the Appalachian Trail. In his free time he enjoys hanging out with friends and playing Ultimate Frisbee."
                }
              />
            </div>
          </Slider>
        </Fade>
      </div>
    );
  }
}

export default Team;
