import React, { PureComponent } from "react";
import ReactGA from "react-ga";
import classes from "./Footer.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function PrivacyPolicy(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          LincLogix Privacy Policy
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 id="linclogix-privacy-policy">LINCLOGIX PRIVACY POLICY</h2>
        <p>
          LincLogix (“Company”) welcomes you to this web site (“Site”). Company
          respects your privacy and is committed to protecting the Personal Data
          (as hereinafter defined) that you share with us. This Privacy Policy
          describes how Company collects and uses the Personal Data that you
          provide to us.
        </p>
        <ol>
          <li>
            <p>Collection of Personal Data</p>
            <ul>
              <li>
                When you browse Company’s Site, we do not automatically collect
                Personal Data (as defined below) from you. We, however, do log
                your IP address (the Internet address of your computer) to give
                us an idea of which part(s) of the Site you visit and the amount
                of time that you spend there. As you access and use Company’s
                Site, Company may request from you certain information, such as
                your name, e-mail address, company name, company address, and
                telephone number (“Personal Data”). This Personal Data is needed
                to provide to you a product or a service or to carry out a
                transaction that you have requested.
              </li>
              <li>
                We will delete your personal information from the active and
                searchable areas of our system at your request. Be aware,
                however, that it is not feasible for us to remove every
                electronic record that may contain your data. We cannot remove
                copies of your information that may exist in records we create
                as backup in case our main data is lost. We also cannot delete
                information from anonymous, aggregated databases. This
                information cannot be linked to you. Company maintains and
                archives all Personal Data provided by users. This data is
                retained in our archives and not deleted.
              </li>
              <li>
                To have your registration information removed, send your request
                by email. You will receive a confirmation from us.
              </li>
            </ul>
          </li>
          <li>
            <p>Cookies and Other Tracking Technologies</p>
            <ul>
              <li>
                This Site may use cookie and tracking technology. A “cookie” is
                a small data file that the Site places on your computer’s hard
                drive for identification purposes. These data files include
                information that allows our Site to remember important
                information that will make your use of our Site more efficient
                and useful to you.
              </li>
            </ul>
          </li>
          <li>
            <p>How Company Uses Personal Data</p>
            <ul>
              <li>
                Company uses Personal Data in several ways: to fulfill your
                requests for certain products and services; to personalize your
                experience on our Site; to keep you up-to-date on the latest
                product announcements; and to provide to you other information
                that we think you would like to hear about from us. We may also
                use your Personal Data to send to you direct marketing
                information or to contact you for market research.
              </li>
            </ul>
          </li>
          <li>
            <p>Disclosure to Third Parties</p>
            <ul>
              <li>
                If you request something from the Site (e.g., a product, a
                service, specific marketing materials, etc.), we may use your
                Personal Data to fulfill your request. To help us do this, we
                may share your Personal Data with third parties to provide
                certain services (e.g., marketing services) on our behalf. In
                such cases, however, the third parties are prohibited from using
                your Personal Data for any other purpose. To describe our
                services to advertisers, vendors, and other reputable third
                parties and for other lawful purposes, Company may disclose
                aggregate statistics about our Site visitors, customers, and
                sales. But these statistics will include no Personal Data.
                Company may disclose Personal Data if required to do so by law
                or if Company believes that such action is necessary to protect
                and defend the rights, property, or personal safety of Company,
                the Site, or its visitors. Company may disclose your Personal
                Data in the event of any sale or transfer of its business or its
                assets.
              </li>
            </ul>
          </li>
          <li>
            <p>Changes to This Privacy Policy</p>
            <ul>
              <li>
                Company reserves the right to amend the terms and provisions of
                this Privacy Policy from time to time. Your continued use of
                this Site after changes have been made will constitute your
                acceptance of any new or amended terms and provisions.
              </li>
            </ul>
          </li>
          <li>
            <p>Children</p>
            <ul>
              <li>
                Company does not knowingly allow children under the age of 13 to
                register on our site for any purpose or to access any features
                that require registration. We require visitors to indicate their
                year of birth to register. If we learn that we inadvertently
                have collected identifying personal information from a child
                under the age of 13, we will promptly delete that information
                from our system.
              </li>
            </ul>
          </li>
        </ol>
        <h3 id="privacy-contact-information">Privacy Contact Information</h3>
        <p>
          If you have any questions, concerns, or comments about our privacy
          policy, you may contact us directly using the following information:
        </p>
        <p>Name: LincLogix</p>
        <p>Email address: craig@linclogix.com</p>
        <p>
          Phone Number: <a href="tel:+13178740200">+1 (317) 874-0200</a>
        </p>
        <p>
          Company makes every effort to safeguard your personal information.
          However, like other Internet sites, we cannot guarantee that it is
          completely secure from people who might attempt to evade security
          measures or intercept transmissions over the Internet. Similarly, we
          cannot completely ensure the privacy of e-mail communications to and
          from our site because they are not encrypted and, therefore, not
          secure. These communications, like most Internet e-mail, could be
          intercepted without your knowledge and permission, while in transit to
          or from our site. If you are concerned about your privacy, we suggest
          that you do not use e-mail to communicate information to us that you
          consider sensitive.
        </p>
        <p>
          This privacy policy is not intended to and does not create any
          contractual or legal rights in or on behalf of any party.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function TermsOfService(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          LincLogix Terms of Service
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 id="linclogix-terms-of-use-policy">
          LincLogix TERMS OF USE POLICY
        </h2>
        <ol>
          <li>
            <p>Rules and Regulations</p>
            <ul>
              <li>
                LincLogix (“Company”) appreciates your visit to this web site
                (“Site”). The following rules and regulations apply to all
                visitors to or users of the Site. For purposes of accessing and
                browsing this Site, you accept, without limitation or
                qualification, these terms and provisions and acknowledge that,
                with regard to your access to or use of this Site, no other
                agreements between you and Company are of any force or effect.
                These rules and regulations apply to all visits to the Site,
                both now and in the future.
              </li>
            </ul>
          </li>
          <li>
            <p>Compliance With Applicable Laws; Export Control Laws</p>
            <ol>
              <li>
                <p>
                  Your access to this Site is governed by all applicable
                  federal, state, and local laws. All information available on
                  the Site is subject to U.S. Export Control laws and may also
                  be subject to the laws of the country where you reside.
                </p>
              </li>
              <li>
                <p>
                  You must be an individual at least 13 years of age to submit
                  any information or register for any service on this Site. If
                  you must register for any service on this Site, you must
                  register under your own name. Please inform Company promptly
                  if there is a change in the information you provided to
                  Company, including change of address or name.
                </p>
              </li>
              <li>
                <p>
                  Users of the Site may not post or transmit any message which
                  is libelous, defamatory or which discloses private or personal
                  matters concerning any person. Users of the Site may not post
                  or transmit any message, data, image or program which is
                  indecent, obscene or pornographic.
                </p>
              </li>
              <li>
                <p>
                  Users of the Site may not post or transmit any message
                  anonymously or under a false name. Users of the Site may not
                  permit any other person (other than an agent acting on behalf
                  of a user of the Site and subject to that User’s supervision)
                  to access the Site using user’s identifying information,
                  username, or password, as applicable, for any purpose.
                </p>
              </li>
              <li>
                <p>
                  Users of the Site may not post or transmit any message, data,
                  image or program that would violate the property rights of
                  others, including unauthorized copyrighted text, images or
                  programs, trade secrets or other confidential proprietary
                  information, and trademarks or service marks used in an
                  infringing fashion.
                </p>
              </li>
              <li>
                <p>
                  Users of the Site may not post or transmit any file which
                  contains viruses, worms, &quot;Trojan horses&quot;, or any
                  other contaminating or destructive features.
                </p>
              </li>
              <li>
                <p>
                  Users of the Site may not post or transmit any message which
                  is harmful, threatening, abusive or hateful. It is not
                  LincLogix’s intent to discourage users from taking
                  controversial positions or expressing vigorously what may be
                  unpopular views; however, Company reserves the right to take
                  such action as it deems appropriate in cases where the Site is
                  used to disseminate statements which are deeply and widely
                  offensive and/or harmful or otherwise unacceptable.
                </p>
              </li>
              <li>
                <p>
                  Users of the Site may not use the facilities and capabilities
                  of the Site to conduct any activity or solicit the performance
                  of any activity which is illegal or which infringes on or
                  violates the rights of others.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>Trademarks &amp; Copyrights</p>
            <ul>
              <li>
                The trademarks, service marks, and logos (“Marks”) displayed on
                this Site are the property of Company or are used with the
                permission of the appropriate third party. Users are not
                permitted to use these Marks without the prior written consent
                of Company or such third party that may own the Mark. The entire
                contents of the Site are copyrighted as a collective work under
                the United States and other copyright laws. Company holds the
                copyright in the collective work. The collective work includes
                works, which are the property of Company and/or Company’s
                licensors, which are also protected by copyright and other
                intellectual property laws. Any redistribution, retransmission
                or publication of any copyrighted material is strictly
                prohibited without the express written consent of the copyright
                owner.
              </li>
            </ul>
          </li>
          <li>
            <p>Information Provided to Company</p>
            <ul>
              <li>
                Company does not want you to, and you should not, send any
                confidential or proprietary information to Company via the Site.
                Any submission by you to Company, including but not limited to
                questions, comments, suggestions, and the like shall be deemed
                to be non-confidential and shall become the property of Company.
                Furthermore, by your submission, you grant to Company
                non-exclusive, worldwide, irrevocable, unrestricted,
                royalty-free, perpetual license, with the right to sublicense,
                to reproduce, distribute, transmit, create derivative works of,
                publicly display, modify, transmit, and publicly perform any
                materials and other information (including, without limitation,
                ideas contained therein for new or improved products or
                services) you submit by all means and in any media now known or
                hereafter developed. Company shall be free to use any ideas,
                concepts, know-how, or techniques contained in such information
                for any purpose whatsoever, including but not limited to
                developing, manufacturing, and marketing products incorporating
                such information. You agree that you shall have no recourse
                against Company for any alleged or actual infringement or
                misappropriation of any proprietary right in your communication
                to Company.
              </li>
            </ul>
          </li>
          <li>
            <p>Links to Third Party Sites</p>
            <ul>
              <li>
                This Site may contain links to web sites of third parties.
                Access to any other web site linked to this Site is at the
                user’s own risk, and Company is not responsible for the accuracy
                or reliability of any information, data, opinions, advice, or
                statements made on these sites. Rather, Company provides these
                links merely as a convenience to you, and the inclusion of such
                links does not imply Company’s endorsement of the site(s).
              </li>
            </ul>
          </li>
          <li>
            <p>Limitation of Liability</p>
            <ul>
              <li>
                <p>
                  UNDER NO CIRCUMSTANCE SHALL COMPANY BE LIABLE FOR ANY DIRECT,
                  INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL
                  DAMAGES THAT RESULT IN ANY WAY FROM YOUR USE OF OR YOUR
                  INABILITY TO USE THE SITE, MERCHANDISE PROVIDED ON OR THROUGH
                  THE SITE, OR THAT RESULT FROM MISTAKES, OMISSIONS,
                  INTERRUPTIONS, DELETIONS OF FILES, ERRORS, DEFECTS, DELAYS IN
                  OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE. THIS
                  EXCLUSION OF CONSEQUENTIAL AND INCIDENTAL DAMAGES MAY NOT
                  APPLY TO YOU, HOWEVER, TO THE EXTENT THAT APPLICABLE LAW MAY
                  NOT ALLOW THE EXCLUSION OF CONSEQUENTIAL AND INCIDENTAL
                  DAMAGES.
                </p>
              </li>
              <li>
                <p>
                  Company’s total liability to you for all losses, damages,
                  claims, and causes of action (whether in contract, tort, or
                  otherwise) will not exceed the direct incremental amount, if
                  any, that you paid to Company for the specific purpose of
                  accessing the Site.
                </p>
              </li>
              <li>
                <p>
                  YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS Company (AND
                  COMPANY’S OWNERS, OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS)
                  FROM ANY AND ALL LIABILITIES, COSTS, AND EXPENSES, INCLUDING
                  REASONABLE ATTORNEYS’ FEES (INCLUDING THE VALUE OF IN-HOUSE
                  COUNSEL), RELATED TO ANY VIOLATION OF THE TERMS AND PROVISIONS
                  OF THE COMPANY TERMS OF USE POLICY BY YOU.
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>Assignment</p>
            <ul>
              <li>
                The rules and regulations under this Company Terms of Use Policy
                may not be assigned by you without the prior written consent of
                Company. Company may assign the rules and regulations under this
                Company Terms of Use Policy, in whole or in part, to any
                affiliate or third party.
              </li>
            </ul>
          </li>
          <li>
            <p>Disclaimer</p>
            <ul>
              <li>
                Company makes no warranty or guarantee concerning the accuracy
                or reliability of the content of the Site or other sites to
                which the Site links. RATHER, THE SITE AND THE INFORMATION,
                SOFTWARE, AND OTHER MATERIAL AVAILABLE ON OR ACCESSIBLE THROUGH
                THE SITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
                WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE, NON-
                INFRINGEMENT, OR IMPLIED WARRANTIES OF MERCHANTABILITY OR
                FITNESS FOR A PARTICULAR PURPOSE. FURTHERMORE, COMPANY DOES NOT
                WARRANT THAT THE WEB SITE SERVICE WILL BE UNINTERRUPTED OR
                ERROR-FREE OR THAT ANY INFORMATION, SOFTWARE, OR OTHER MATERIAL
                AVAILABLE ON OR ACCESSIBLE THROUGH THE SITE IS FREE OF VIRUSES,
                WORMS, TROJAN HORSES, OR OTHER HARMFUL COMPONENTS. THIS
                EXCLUSION OF IMPLIED WARRANTIES MAY NOT APPLY TO YOU, HOWEVER,
                TO THE EXTENT THAT APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF
                IMPLIED WARRANTIES.
              </li>
            </ul>
          </li>
          <li>
            <p>Disputes</p>
            <ol>
              <li>
                <p>
                  Any controversy or claim arising out of or in connection with
                  the rules and regulations of this Company Terms of Use Policy,
                  their enforcement, or their interpretation (“Dispute”) will be
                  finally resolved solely in accordance with the terms and
                  provisions of this Section.
                </p>
              </li>
              <li>
                <p>
                  If a Dispute arises, Company and the user agree to submit such
                  Dispute to binding arbitration only, to be conducted pursuant
                  to the rules of the American Arbitration Association, such
                  arbitration to be held in Indianapolis, Indiana.
                  Notwithstanding the preceding sentence, in the event that
                  third parties are necessary to achieve a just adjudication of
                  the issues, either party may commence a civil action in a
                  court of competent jurisdiction having jurisdiction over all
                  such parties. The parties are entitled to limited discovery
                  under the local rules of civil procedure for courts of general
                  jurisdiction. The parties further agree that any monetary
                  award may be reduced to judgment and docketed in any court of
                  competent jurisdiction without objection and execution had
                  thereon.
                </p>
              </li>
              <li>
                <p>
                  No arbitration or action with regard to a Dispute may be
                  brought more than (1) year after the Dispute, claim, or cause
                  of action arose.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>Indemnification</p>
            <ul>
              <li>
                You agree to indemnify, defend and hold harmless Company, its
                officers, directors, employees, agents, suppliers and Licensors
                (collectively the &quot;Service Providers&quot;) from and
                against all losses, expenses, damages and costs, including
                reasonable attorneys&#39; fees, resulting from any violation of
                this Agreement or any activity related to your account
                (including negligent or wrongful conduct) by you or any other
                person accessing the Site.
              </li>
            </ul>
          </li>
          <li>
            <p>Governing Law</p>
            <ul>
              <li>
                By accessing and browsing this Site, you agree that Indiana law,
                without regard to its conflict of law principles, will apply to
                all matters relating to the use of this Site. You and Company
                also agree and hereby submit to the exclusive personal
                jurisdiction of the district courts of the State of Indiana
                and/or the Federal District Court in and for the State of
                Indiana with respect to all actions pertaining to any and all
                Disputes. Furthermore, you and Company agree that, for purposes
                of all Disputes, venue shall be in Hamilton County, State of
                Indiana for state court matters and in the Southern District of
                Indiana for matters in federal court.
              </li>
            </ul>
          </li>
        </ol>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

class Footer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPrivacyPolicy: false,
      showTOS: false,
    };
  }

  render() {
    return (
      <div className={classes.footer}>
        <div className={classes.wrapper}>
          <PrivacyPolicy
            show={this.state.showPrivacyPolicy}
            onHide={() => {
              this.setState({ showPrivacyPolicy: false });
            }}
          />
          <TermsOfService
            show={this.state.showTOS}
            onHide={() => {
              this.setState({ showTOS: false });
            }}
          />
          <Row sm={1} xs={1} md={2} lg={2} xl={2}>
            <Col>
              <img
                height="100"
                alt="LincLogix Logo"
                src={require("../../assets/logo.png")}
              />
              <br />
              <br />
              <strong>LincLogix LLC</strong>
              <br />
              7439 Woodland Drive
              <br />
              Suite 210
              <br />
              Indianapolis, IN 46278
              <hr style={{ background: "white" }} />
              <b>LincLogix is a BCM One Company.</b>
            </Col>
            <Col>
              <button
                className={classes.button}
                onClick={() => {
                  ReactGA.event({
                    category: "User",
                    action: "Open Privacy Policy of Service",
                  });
                  this.setState({ showPrivacyPolicy: true });
                }}
              >
                Privacy Policy
              </button>
              <br />
              <button
                className={classes.button}
                onClick={() => {
                  ReactGA.event({
                    category: "User",
                    action: "Open Terms of Service",
                  });
                  this.setState({ showTOS: true });
                }}
              >
                Terms of Service
              </button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Footer;
