import React, { PureComponent } from "react";

import classes from "./Rollouts.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Fade from "react-reveal/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Rollouts extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className={classes.rollouts}>
        <Container>
          <Fade top>
            <div className={classes.header}>Nationwide Scale</div>
          </Fade>
          <Fade top>
            <div className={classes.subtitle}>
              With decades of experience deploying at a Nationwide scale,
              LincLogix stands ready to help your business deploy anywhere
            </div>
          </Fade>
          <Fade top>
            <div style={{ maxWidth: "25rem", margin: "0 auto" }}>
              <hr />
            </div>
          </Fade>
        </Container>
        <Container fluid className={classes.backdrop}>
          <Row
            xs={1}
            sm={1}
            md={1}
            lg={3}
            xl={3}
            style={{ justifyContent: "center" }}
          >
            <Col>
              <Fade bottom className={classes.fade}>
                <div className={classes.card}>
                  <div className={classes.body}>
                    <div className={classes.icon}>
                      <FontAwesomeIcon icon={["fas", "globe-americas"]} />
                    </div>
                    <div className={classes.cardHeader}>
                      Complete Coordination
                    </div>
                    Handled by a single point of contact on the LincLogix
                    project management team, your entire technology rollout will
                    be managed, controlled and coordinated from beginning to
                    end.
                  </div>
                </div>
              </Fade>
            </Col>
            <Col>
              <Fade bottom className={classes.fade}>
                <div className={classes.card}>
                  <div className={classes.body}>
                    <div className={classes.icon}>
                      <FontAwesomeIcon icon={["fas", "binoculars"]} />
                    </div>
                    <div className={classes.cardHeader}>Site Surveys</div>
                    LincLogix can provide complete site surveys to verify
                    physical requirements and identify any unique
                    characteristics of the project.
                  </div>
                </div>
              </Fade>
            </Col>
            <Col>
              <Fade bottom className={classes.fade}>
                <div className={classes.card}>
                  <div className={classes.body}>
                    <div className={classes.icon}>
                      <FontAwesomeIcon icon={["fas", "tools"]} />
                    </div>
                    <div className={classes.cardHeader}>Site Installation</div>
                    Whether you are moving or building, with LincLogix you don't
                    have to worry about getting your services setup. LincLogix
                    will coordinate all installations so you don't have to.
                  </div>
                </div>
              </Fade>
            </Col>
            <Col>
              <Fade bottom className={classes.fade}>
                <div className={classes.card}>
                  <div className={classes.body}>
                    <div className={classes.icon}>
                      <FontAwesomeIcon icon={["fas", "angle-double-up"]} />
                    </div>
                    <div className={classes.cardHeader}>Network Upgrades</div>
                    LincLogix is able to coordinate any required changes or
                    modifications to topology of our clients’ networks. We
                    pre-configure equipment at our partner facilities prior to
                    deployment in order to ensure that everything is working
                    according to spec. This system of deployment helps save time
                    and ensures a quality installation.
                  </div>
                </div>
              </Fade>
            </Col>
            <Col>
              <Fade bottom className={classes.fade}>
                <div className={classes.card}>
                  <div className={classes.icon}>
                    <FontAwesomeIcon icon={["fas", "truck-moving"]} />
                  </div>
                  <div className={classes.body}>
                    <div className={classes.cardHeader}>
                      Service that moves with you
                    </div>
                    With LincLogix, you never have to worry about losing your
                    service when you move. We take care of the logistics so you
                    can get working in your new location faster.
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Rollouts;
