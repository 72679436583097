import React from "react";
import ReactGA from "react-ga";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../src/components/NavBar/NavBar";
import Home from "../src/components/Home/Home";
// import Card from "../src/components/Card/Card";
import Solutons from "./components/Solutions/Solutions";
import Knowledge from "./components/Knowledge/Knowledge";
import Rollouts from "./components/Rollouts/Rollouts";
import Testimonials from "./components/Testimonials/Testimonials";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Team from "./components/Team/Team";
// import Announcement from "./components/Announcement/Announcement";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faQuoteRight,
  faQuoteLeft,
  faBinoculars,
  faTruckMoving,
  faGlobeAmericas,
  faTools,
  faAngleDoubleUp,
  faHandshake,
  faLightbulb,
  faInfo,
  faStar,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faQuoteRight,
  faQuoteLeft,
  faBinoculars,
  faTruckMoving,
  faGlobeAmericas,
  faTools,
  faAngleDoubleUp,
  faHandshake,
  faLightbulb,
  faInfo,
  faStar,
  faTrophy
);

function initializeReactGA() {
  ReactGA.initialize("UA-170977289-1");
  ReactGA.pageview("/homepage");
}

initializeReactGA();

function App() {
  if (window.location.protocol !== "https:") {
    window.location.replace(
      `https:${window.location.href.substring(window.location.protocol.length)}`
    );
  }
  return (
    <div className="App">
      {/* <Announcement /> */}
      <NavBar />
      <Home />
      <div id="solutions">
        <Solutons />
      </div>
      <div id="knowledge">
        <Knowledge />
      </div>
      <div id="scale">
        <Rollouts />
      </div>
      <div id="team">
        <Team />
      </div>
      <div id="testimonials">
        <Testimonials />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
