import React, { PureComponent } from "react";

import classes from "./Solutions.module.css";
import Slide from "react-reveal/Slide";
import { Parallax } from "react-parallax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Solutions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <Parallax
          bgImage={require("../../assets/computer.jpg")}
          bgImageAlt="picture of the back of a computer"
          strength={500}
          className={classes.outerMargin}
        >
          <Slide left>
            <div className={classes.card}>
              <div className={classes.container}>
                <div className={classes.header}>
                  Solutions
                  <span style={{ float: "right" }}>
                    <FontAwesomeIcon icon={["fas", "handshake"]} />
                  </span>
                </div>
                <hr />
                <div className={classes.body}>
                  We deliver a wide range of network solutions to successful
                  clients all over the U.S. Our end-to-end capabilities allow us
                  to implement the services that incorporate your specific
                  network requirements.
                  <br />
                  <br />
                  Because the first thing we do is sit down with a new client
                  and listen, when we understand your business and your needs,
                  we can better design effective solutions, tailored just for
                  you.
                </div>
                <br />
                <div className={classes.body}>
                  <b>Primary Services</b>
                </div>
                <div className={classes.body}>
                  <ul>
                    <li>Enterprise Networking</li>
                    <li>Data Center & Cloud Design</li>
                    <li>Unified Communication & Contact Center Solutions</li>
                    <li>Cloud Interconnection</li>
                    <li>Software Defined Wide Area Networking</li>
                    <li>AWS & Azure Consulting</li>
                  </ul>
                </div>
              </div>
            </div>
          </Slide>
        </Parallax>
      </div>
    );
  }
}

export default Solutions;
