import React, { PureComponent } from "react";

import classes from "./TeamCard.module.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Button from "react-bootstrap/Button";

class TeamCard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    // console.log(this.props);
    return (
      <div>
        <div className={classes.card}>
          <div className={classes.container}>
            <div className={classes.titleWrapper}>
              <Row xs={1} sm={2} md={2}>
                <Col>
                  <img
                    className={classes.image}
                    src={require(`../../assets/${this.props.image}`)}
                    alt="Staff"
                  />
                </Col>
                <Col>
                  <div className={classes.name}>{this.props.name}</div>
                  <div className={classes.position}>{this.props.position}</div>
                </Col>
              </Row>
            </div>
            <hr />
            <Accordion defaultActiveKey="about">
              <Card
                style={{ border: "none", borderRadius: "10px 10px 0px 0px" }}
              >
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="about"
                  style={{
                    border: "none",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <strong>
                    About{" "}
                    <span style={{ float: "right" }}>
                      <FontAwesomeIcon icon={["fas", "info"]} />
                    </span>
                  </strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="about">
                  <Card.Body>
                    {this.props.about ? this.props.about : "Not Available"}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card style={{ border: "none" }}>
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="accomplishments"
                  style={{
                    border: "none",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <strong>
                    Accomplishments
                    <span style={{ float: "right" }}>
                      <FontAwesomeIcon icon={["fas", "trophy"]} />
                    </span>
                  </strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="accomplishments">
                  <Card.Body>
                    {this.props.accomplishments
                      ? this.props.accomplishments
                      : "Not Available"}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card
                style={{ border: "none", borderRadius: "0px 0px 10px 10px" }}
              >
                <Accordion.Toggle
                  as={Card.Header}
                  variant="link"
                  eventKey="funFacts"
                  style={{
                    border: "none",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <strong>
                    Fun Facts
                    <span style={{ float: "right" }}>
                      <FontAwesomeIcon icon={["fas", "star"]} />
                    </span>
                  </strong>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="funFacts">
                  <Card.Body>
                    {this.props.funFacts
                      ? this.props.funFacts
                      : "Not Available"}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamCard;
