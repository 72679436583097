import React, { PureComponent } from "react";
import classes from "./Navbar.module.css";
import ReactGA from "react-ga";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { Link, animateScroll as scroll } from "react-scroll";

class NavBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }
  scrollToTop = () => {
    scroll.scrollToTop();
  };
  render() {
    return (
      <div>
        {/* <Container fluid className={classes.navbar}>
          <img
            className={classes.image}
            src={require("../../assets/logo_clear.png")}
          />
        </Container> */}
        <Navbar
          expand="md"
          collapseOnSelect={true}
          className={classes.navbar}
          fixed="top"
        >
          <Navbar.Brand
            onClick={() => {
              ReactGA.event({
                category: "Navigation",
                action: "Clicked Logo",
              });

              this.scrollToTop();
            }}
          >
            <img
              // className={classes.image}
              alt="LincLogix Logo"
              height="75"
              className="d-inline-block align-top"
              src={require("../../assets/logo.png")}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {/* <Nav.Link href="#features">Features</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link>
              <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
            <Nav>
              <Link
                activeClass="active"
                to="solutions"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                href="active"
                onClick={() => {
                  ReactGA.event({
                    category: "Navigation",
                    action: "Clicked Solutions",
                  });
                }}
              >
                Solutions
              </Link>
              &nbsp; &nbsp; &nbsp;
              <Link
                activeClass="active"
                to="knowledge"
                spy={true}
                smooth={true}
                offset={-50}
                duration={1000}
                href="knowledge"
                onClick={() => {
                  ReactGA.event({
                    category: "Navigation",
                    action: "Clicked Knowledge",
                  });
                }}
              >
                Knowledge
              </Link>
              &nbsp; &nbsp; &nbsp;
              <Link
                activeClass="active"
                to="scale"
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                href="scale"
                onClick={() => {
                  ReactGA.event({
                    category: "Navigation",
                    action: "Clicked Scale",
                  });
                }}
              >
                Scale
              </Link>
              &nbsp; &nbsp; &nbsp;
              <Link
                activeClass="active"
                to="team"
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                href="team"
                onClick={() => {
                  ReactGA.event({
                    category: "Navigation",
                    action: "Clicked Team",
                  });
                }}
              >
                Team
              </Link>
              &nbsp; &nbsp; &nbsp;
              <Link
                activeClass="active"
                to="testimonials"
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                href="testimonials"
                onClick={() => {
                  ReactGA.event({
                    category: "Navigation",
                    action: "Clicked Testimonials",
                  });
                }}
              >
                Testimonials
              </Link>
              &nbsp; &nbsp; &nbsp;
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={1000}
                href="contact"
                onClick={() => {
                  ReactGA.event({
                    category: "Navigation",
                    action: "Clicked Contact",
                  });
                }}
              >
                Contact
              </Link>
              &nbsp; &nbsp; &nbsp;
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default NavBar;
