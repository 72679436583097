import React, { Component } from "react";
import classes from "./Announcement.module.css";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import ButtonGroup from "react-bootstrap/ButtonGroup";

export default class Announcement extends Component {
  render() {
    return (
      <div className={classes.card}>
        <h2>
          <b>News from LincLogix</b>
        </h2>
        <h4>
          <b>By Craig S. Eigenbrod, President</b>
        </h4>
        <hr />
        <p>To Our Beloved LincLogix Clients,</p>
        <p>
          We have exciting organizational news to share. LincLogix has been
          acquired by{" "}
          <b>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bcmone.com/"
            >
              BCM One
            </a>
          </b>
          , and we are now part of BCM One’s technology family. BCM One has been
          a critical partner of LincLogix’s for over 15 years, and this is both
          a strategic and natural evolution of our business relationship.
        </p>
        <p>
          BCM One, a trusted managed technology solutions provider based in New
          York City, has been in business for over 28 years and offers an array
          of technology solutions, including UCaaS, SIP, managed connectivity,
          managed SD-WAN, Microsoft Teams, Azure and technology expense
          optimization. They are an experienced and highly respected partner to
          businesses nationwide maintaining an average NPS score of 88 year over
          year.
        </p>
        <p>
          Effective immediately, the LincLogix staff and I will become part of
          the BCM One family, and in the coming months we will begin to
          transition to the BCM One name and brand.
        </p>
        <p>
          <b>What does this mean for you?</b>
        </p>
        <p>
          On a day-to-day basis, it’s business as usual. There will be no
          changes to your service as, most likely, at least some of the
          solutions that you are currently receiving from LincLogix include BCM
          One, from a technology perspective. This strategic move will benefit
          you, as a client, in the following ways:
          <ul>
            <li>
              BCM One supplements our service efforts, by adding a team of 40+
              professionals to our Operations team, dedicated to providing you
              outstanding technical support and service delivery. Their culture,
              like LincLogix, takes each client interaction very seriously. And
              similar to our staff’s tenure, you will find that many BCM One
              employees have been with the company for 10+ years, which says a
              lot about their culture and family-like atmosphere which extends
              to their clients.
            </li>
            <li>
              By virtue of this acquisition, you now have access to a robust
              multi-technology Network Operations Center, ensuring the varied
              services you receive from us, are supported 24/7/365.
            </li>
          </ul>
        </p>
        <p>
          I would like to take this opportunity to thank each and every one of
          you for putting your trust in LincLogix over the past 20 years. We
          appreciate your business and look forward to continuing our
          partnership.
        </p>
        <p>
          With Sincerest Gratitude,
          <br />
          Craig S. Eigenbrod, President
        </p>
        <hr />
        <h4>Press Coverage</h4>
        <ul>
          <li>
            <b>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bcmone.com/company/press-releases/bcm-one-acquires-regional-solutions-provider-linclogix/"
              >
                BCM One Press Release
              </a>
            </b>
          </li>
          <li>
            <b>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://channelvisionmag.com/bcm-one-acquires-regional-solutions-provider-linclogix/"
              >
                BCM One Acquires Regional Solutions Provider LincLogix - Channel
                Vision Magazine
              </a>
            </b>
          </li>
          <li>
            <b>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.insideindianabusiness.com/story/43135866/technology-company-acquires-indys-linclogix"
              >
                Technology Company Acquires Indy's LincLogix - Inside Indiana
                Business
              </a>
            </b>
          </li>
          <li>
            <b>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://telecomreseller.com/2021/01/05/bcm-one-acquires-regional-solutions-provider-linclogix/"
              >
                BCM One Acquires Regional Solutions Provider LincLogix - Telecom
                Reseller
              </a>
            </b>
          </li>
        </ul>
      </div>
    );
  }
}
