import React, { PureComponent } from "react";
import classes from "./Contact.module.css";

// import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
import Fade from "react-reveal/Fade";
// import Collapse from "react-bootstrap/Collapse";
// import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";

import emailjs from "emailjs-com";

class Contact extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      company: "",
      phone: "",
      help: "",
      submitForm: false,
      success: false,
      failure: false,
    };

    this.sendEmail = this.sendEmail.bind(this);
  }

  sendEmail(e) {
    e.preventDefault();
    this.setState({ submitForm: true });
    emailjs
      .sendForm(
        "outlook",
        "contactus_form",
        e.target,
        "user_sX6PUVR0y30eLmKTJI1cB"
      )
      .then(
        (result) => {
          console.log(result.text);
          ReactGA.event({ category: "User", action: "Contact Us Form" });
          this.setState({ success: true, submitForm: false });
        },
        (error) => {
          console.log(error.text);
          this.setState({ failure: true, submitForm: false });
        }
      );
  }

  render() {
    // console.log(this.state);
    return (
      <div className={classes.contact}>
        <Fade top>
          <div className={classes.header}>Contact</div>
        </Fade>
        <Fade top>
          <div className={classes.subtitle}>
            Interested in teaming up with us to improve your business? We’re
            ready to listen!
            <br />
            Give us a call at{" "}
            <b>
              <a href="tel:+13178740200">+1 (317) 874-0200</a>
            </b>{" "}
            and we will get in touch with you shortly!
          </div>
        </Fade>
        {/* <Fade top>
          <div style={{ maxWidth: "25rem", margin: "0 auto" }}>
            <hr />
          </div>
        </Fade> */}
        {/* <Fade bottom>
          <Collapse in={this.state.success === false}>
            <div>
              <div className={classes.form}>
                <Form
                  onSubmit={(e) => {
                    this.sendEmail(e);
                  }}
                >
                  <Form.Row className={classes.row}>
                    <Col>
                      <Form.Control
                        name="name"
                        placeholder="Name"
                        required
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        name="email"
                        placeholder="Email"
                        required
                        type="email"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className={classes.row}>
                    <Col>
                      <Form.Control
                        name="company"
                        placeholder="Company Name"
                        onChange={(e) => {
                          this.setState({ company: e.target.value });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        name="phone"
                        placeholder="Phone Number"
                        required
                        onChange={(e) => {
                          this.setState({ phone: e.target.value });
                        }}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className={classes.row}>
                    <Col>
                      <Form.Control
                        name="help"
                        as="textarea"
                        rows="10"
                        placeholder="How can we help you?"
                        onChange={(e) => {
                          this.setState({ help: e.target.value });
                        }}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row className={classes.row}>
                    <Button
                      className={classes.button}
                      type="submit"
                      disabled={this.state.submitForm}
                    >
                      {this.state.submitForm ? "Sending..." : "Contact Us"}
                    </Button>
                  </Form.Row>
                </Form>
              </div>
            </div>
          </Collapse>
          <Collapse in={this.state.success}>
            <div>
              <Alert variant="success" className={classes.alert}>
                <Alert.Heading>We Received Your Information</Alert.Heading>A
                member of the LincLogix team will contact you shortly. If you
                need to get in touch faster, try calling our number at{" "}
                <Alert.Link href="tel:+13178740200">
                  +1 (317) 874-0200
                </Alert.Link>
              </Alert>
            </div>
          </Collapse>
          <Collapse in={this.state.failure}>
            <div>
              <Alert variant="danger" className={classes.alert}>
                <Alert.Heading>Something Went Wrong</Alert.Heading>
                Whoops. Looks like something didn't work correctly. Please try
                submitting again. If the issue persists try calling us at{" "}
                <Alert.Link href="tel:+13178740200">
                  +1 (317) 874-0200
                </Alert.Link>
              </Alert>
            </div>
          </Collapse>
        </Fade> */}
      </div>
    );
  }
}

export default Contact;
