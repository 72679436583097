import React, { PureComponent } from "react";

import classes from "./Home.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Fade from "react-reveal/Fade";
import Announcement from "../Announcement/Announcement";

class Home extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Container fluid className={classes.home}>
        {/* <h1>LincLogix</h1> */}
        <Row className="justify-content-md-center">
          <div className={classes.textWrapper}>
            <div className={classes.header}>
              <strong>
                <Fade top>
                  When you’re smaller than a giant, it’s easier to keep your ear
                  to the ground.
                </Fade>
              </strong>
            </div>
          </div>
        </Row>
        <Row className="justify-content-md-center">
          <div className={classes.textWrapper}>
            <br />
            <Fade top>
              <div className={classes.subtitle}>
                LincLogix prides itself on being smaller than the giant
                companies we compete with. Because we’re small we listen better,
                move faster, and partner effectively.
              </div>
            </Fade>
            <br />
            {/* <button className={classes.button}>
              <strong>Learn More</strong>
            </button> */}
          </div>
        </Row>
        <Row>
          <Col>
            <Fade bottom>
              <Announcement />
            </Fade>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Home;
